import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Spinner = ({ path = "login" }) => {
    const [count, setCount] = useState(2);
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo } = useSelector((state) => state.auth);

    
    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevValue) => --prevValue);
        }, 4000);
        count === 0 &&
            navigate(`/${path}`, {
                state: location.pathname,
            });
        return () => clearInterval(interval);
    }, [count, navigate, location, path]);
    return (
        <>
        {count !== 0 ?
        <div
            className="flex flex-col justify-center items-center w-full"
            style={{ height: "100vh", background: "#0000001c" }}
            >
           

<div className="game-loader">
  <div className="game-loader__planet">
    <div className="loader-radius1" />
    <div className="loader-radius2" />
    <div className="loader-radius3" />
    <div className="loader-radius4" />
    <div className="loader-mini1" />
    <div className="loader-mini2" />
    <div className="loader-mini3" />
    <div className="loader-mini4" />
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation={15} result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 26 -7" result="goo" />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </defs>
    </svg>
  </div>
</div>




            <h1 className="text-center">{count}</h1>
        </div>
        : ""}
        </>
    );
};

export default Spinner;
