import React , { useState, useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import Spinner from "./Spinner";

import { api } from "../store/reducer/api";
export default function PrivateRoute() {
    const [ok, setOk] = useState(true);
const [user,setUser]=useState("")
    

    const handleApi=async()=>{
        try {
            const data=await api.get("/user-auth",{ withCredentials: true })
setUser(data.data)
        } catch (error) {
            
        }
    }
    useEffect(() => {
        handleApi()
        const interval = setInterval(() => {
                          
                if (user?.ok) {
                    setOk(true);
                } else {
                    setOk(false);
                }
        
        }, 1000);
     

        return () => clearInterval(interval);
    }, [user?.length]);

    return ok ? <Outlet /> : <Spinner />;
}