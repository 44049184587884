import React, { useEffect } from "react";
import img from "./Assets/contact_us.png";
import watsapp from "./Assets/png-clipart-whatsapp-logo-whatsapp-logo-desktop-computer-icons-viber-grass-viber.png";
import "./style.css";
import { Link } from "react-router-dom";
import { adminSetting } from "../store/reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";

const Support = () => {
  const dispatch = useDispatch();
  const { adminSettingData } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(adminSetting());
  }, []);
  console.log("adminSettingData", adminSettingData);
  return (
    <div className="support">
      <img src={img} alt="" />
      <h3 className="text[0.7rem]">Support timing (24*7)</h3>
      <h3 className="text[0.7rem]">(Monday to Sunday (All time))</h3>
      <Link
        className="text-[1rem]"
        to={`https://wa.me/${
          Array.isArray(adminSettingData)
            ? adminSettingData[0]?.whatsapp
            : "not found"
        }`}
      >
        <img src={watsapp} alt="" />
        chat with us on whatsapp (Can I help you?)
      </Link>

      <Link
        className="text-[1rem] mt-10"
        to={`https://t.me/${
          Array.isArray(adminSettingData)
            ? adminSettingData[0]?.telegram
            : "not found"
        }`}
      >
        <img src={watsapp} alt="" />
        chat with us on Telegram (Can I help you?)
      </Link>
    </div>
  );
};

export default Support;
